import React, { Component } from "react";
import "../../style/HeaderBeforeLogin.css";

class Header extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <nav className="HeaderBeforeLogin">
        <div className="left">
          <ul className="nav">
            <li>
              <a href="/prijs">Prijs</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <a className="center" href="../../"></a>
        <div className="right">
          <a href="/login">login</a>
        </div>
      </nav>
    );
  }
}

export default Header;
