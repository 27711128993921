import React, { Component } from "react";
import Image from "../../Images/Logo-MTB-Oosterhout_eind.png";

class Header extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div>
        <footer>
          <h3>Trotse sponsor van:</h3>
          <div className="sponsors">
            <a href="https://www.mtboosterhout.nl/?">
              <img
                src={Image}
                alt="Club Logo 2"
                style={{ backgroundColor: "#dddddd" }}
              />
            </a>
          </div>
          <br />
          <p style={{ color: "#dddddd" }}>
            &copy; {new Date().getFullYear()} Melis It Solutions. All rights
            reserved.
          </p>
          <div className="footer-buttons" style={{ marginTop: "10px" }}>
            <button
              style={{
                padding: "5px 10px",
                marginRight: "10px",
                backgroundColor: "#011638",
                color: "#DDDDDD",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => window.open("/TermsofService", "_blank")}
            >
              Terms of Service
            </button>
            <button
              style={{
                padding: "5px 10px",
                backgroundColor: "#011638",
                marginRight: "10px",
                color: "#DDDDDD",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => window.open("/FAQ", "_blank")}
            >
              Veel gestelde vragen
            </button>
            <button
              style={{
                padding: "5px 10px",
                marginRight: "10px",
                backgroundColor: "#011638",
                color: "#DDDDDD",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => window.open("/Privacy", "_blank")}
            >
              Data Privacy Notice
            </button>
          </div>
        </footer>
      </div>
    );
  }
}

export default Header;
