import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();
const ROL_API_BASE_URL = process.env.REACT_APP_API_URL +'rol'
class RolService {
  async GetAll () {
    return await axios.get(ROL_API_BASE_URL + '/all')
  }
}

export default new RolService()
