import React, { Component } from "react";
import "../../style/Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="homepage">
        <nav className="header">
          <div className="left">
            <ul className="nav">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/leden">leden</a>
              </li>
              <li>
                <a href="/evenementen">evenementen</a>
              </li>
              <li>
                <a href="/Groepen">Groepen</a>
              </li>
              <li>
                <a href="/Settings">AccountSettings</a>
              </li>
            </ul>
          </div>
          <div className="right"></div>
        </nav>
      </div>
    );
  }
}

export default Header;
