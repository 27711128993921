import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Contact = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        <div style={{ margin: "3vw" }}>
          <h1>Algemene Voorwaarden</h1>
          <p>
            <strong>Ingangsdatum:</strong> 01-10-2024
          </p>

          <p>
            Welkom bij All-Active-It, een platform dat is ontworpen om
            sportclubs te helpen hun leden te volgen en hun activiteiten
            effectief te beheren. Door toegang te krijgen tot of gebruik te
            maken van onze diensten, gaat u akkoord met en bent u gebonden aan
            de volgende Algemene Voorwaarden. Lees deze zorgvuldig door voordat
            u het platform gebruikt.
          </p>

          <h2>1. Aanvaarding van Voorwaarden</h2>
          <p>
            Door toegang te krijgen tot of gebruik te maken van het
            All-Active-It platform ("Dienst"), erkent u dat u deze Algemene
            Voorwaarden hebt gelezen, begrepen en ermee akkoord gaat gebonden te
            zijn, evenals onze <a href="../privacy">Privacyverklaring</a>. Als u
            niet akkoord gaat met deze voorwaarden, is het u verboden de Dienst
            te gebruiken.
          </p>

          <h2>2. Wijzigingen in de Voorwaarden</h2>
          <p>
            All-Active-It behoudt zich het recht voor om deze Voorwaarden op elk
            moment bij te werken of te wijzigen. We zullen kennisgeving van
            belangrijke wijzigingen geven door deze op het platform te plaatsen.
            Voortgezet gebruik van de Dienst na wijzigingen betekent dat u
            akkoord gaat met de herziene voorwaarden. Het is uw
            verantwoordelijkheid om deze voorwaarden regelmatig te controleren.
          </p>

          <h2>3. Gebruik van de Dienst</h2>
          <p>
            U stemt ermee in de Dienst alleen voor wettelijke doeleinden en in
            overeenstemming met alle toepasselijke wetten en regelgeving te
            gebruiken. De volgende activiteiten zijn verboden op het platform:
          </p>
          <ul>
            <li>
              Betrokken zijn bij enige illegale activiteit of frauduleus gedrag.
            </li>
            <li>
              Het gebruik van geautomatiseerde tools om toegang te krijgen tot
              of te interageren met het platform zonder toestemming.
            </li>
            <li>
              Het verspreiden van virussen, malware of andere schadelijke
              software.
            </li>
            <li>
              Het plaatsen of delen van inhoud die inbreuk maakt op
              intellectuele eigendomsrechten.
            </li>
            <li>
              Betrokken zijn bij kwetsend, intimiderend of bedreigend gedrag
              jegens anderen.
            </li>
          </ul>

          <h2>4. Verantwoordelijkheden van het Account</h2>
          <p>
            Wanneer u een account aanmaakt, bent u verantwoordelijk voor het
            handhaven van de beveiliging en vertrouwelijkheid van uw
            inloggegevens. U stemt ermee in ons onmiddellijk op de hoogte te
            stellen van ongeautoriseerd gebruik van uw account. U bent
            verantwoordelijk voor alle activiteiten die plaatsvinden onder uw
            account.
          </p>

          <h2>5. Abonnement en Betaling</h2>
          <p>
            Sommige functies van All-Active-It kunnen een betaald abonnement
            vereisen. Door u te abonneren, stemt u ermee in de kosten te betalen
            die zijn verbonden aan het abonnementsniveau dat u selecteert. U
            bent verantwoordelijk voor het verstrekken van geldige
            betalingsinformatie. We behouden ons het recht voor om uw toegang
            tot de Dienst op te schorten of te annuleren als uw betaling
            mislukt.
          </p>

          <p>
            Alle abonnementsprijzen zijn niet-restitueerbaar, behalve zoals
            vereist door de wet. Als u uw abonnement annuleert, heeft u nog
            steeds toegang tot de Dienst voor de resterende abonnementsperiode.
          </p>

          <h2>6. Intellectuele Eigendom</h2>
          <p>
            Alle inhoud, handelsmerken en gegevens op dit platform, inclusief
            maar niet beperkt tot tekst, graphics, logo's en software, zijn
            eigendom van All-Active-It of haar licentiegevers. Ongeautoriseerd
            gebruik van enige inhoud is strikt verboden. U mag geen inhoud
            kopiëren, reproduceren, distribueren of afgeleide werken creëren
            zonder vooraf schriftelijke toestemming.
          </p>

          <h2>7. Door Gebruikers Gecreëerde Inhoud</h2>
          <p>
            U kunt toestemming krijgen om inhoud te uploaden, in te dienen of te
            delen via het platform, zoals clubinformatie, ledengegevens of
            communicatie. Door inhoud in te dienen, verleent u All-Active-It een
            niet-exclusieve, wereldwijde, royalty-vrije en sublicentieerbare
            licentie om uw inhoud te gebruiken, weer te geven, reproduceren en
            distribueren op het platform.
          </p>

          <p>
            U bent verantwoordelijk voor het waarborgen dat de inhoud die u
            levert geen inbreuk maakt op rechten van derden, inclusief
            intellectuele eigendomsrechten of privacyrechten. We behouden ons
            het recht voor om gebruikersinhoud die in strijd is met deze
            Voorwaarden te verwijderen of te wijzigen.
          </p>

          <h2>8. Gegevensprivacy</h2>
          <p>
            Uw gebruik van de Dienst wordt ook geregeld door onze{" "}
            <a href="../privacy">Privacyverklaring</a>, die uitlegt hoe we uw
            persoonsgegevens verzamelen, gebruiken en beschermen. Door gebruik
            te maken van de Dienst, stemt u in met de verzameling en het gebruik
            van uw gegevens zoals beschreven in onze Privacyverklaring.
          </p>

          <h2>9. Beperking van Aansprakelijkheid</h2>
          <p>
            Voor zover toegestaan door de wet, zijn All-Active-It en haar
            dochterondernemingen, directeuren, werknemers of agenten niet
            aansprakelijk voor enige indirecte, incidentele, speciale, gevolg-
            of bestraffende schade, inclusief maar niet beperkt tot verlies van
            gegevens, inkomsten of zakelijke kansen, die voortvloeit uit uw
            gebruik van de Dienst.
          </p>

          <h2>10. Afwijzing van Garanties</h2>
          <p>
            De Dienst wordt aangeboden op een "as-is" en "as-available" basis.
            All-Active-It geeft geen garanties of verklaringen van welke aard
            dan ook, expliciet of impliciet, met betrekking tot de werking of
            beschikbaarheid van de Dienst, of de informatie, inhoud of
            materialen die zijn opgenomen. We garanderen niet dat de Dienst vrij
            zal zijn van fouten of ononderbroken zal zijn.
          </p>

          <h2>11. Schadeloosstelling</h2>
          <p>
            U stemt ermee in All-Active-It, haar dochterondernemingen en hun
            respectieve functionarissen, werknemers en agenten schadeloos te
            stellen en te beschermen tegen eventuele claims, aansprakelijkheden,
            schade, verliezen en uitgaven (inclusief juridische kosten) die
            voortvloeien uit uw gebruik van de Dienst of enige schending van
            deze Voorwaarden.
          </p>

          <h2>12. Beëindiging</h2>
          <p>
            All-Active-It kan uw toegang tot de Dienst op elk moment opschorten
            of beëindigen, met of zonder reden of kennisgeving, inclusief voor
            enige schending van deze Voorwaarden. Na beëindiging eindigt uw
            recht om de Dienst te gebruiken onmiddellijk, en eventuele gegevens
            of inhoud die aan uw account zijn gekoppeld, kunnen worden
            verwijderd.
          </p>

          <h2>13. Toepasselijk Recht</h2>
          <p>
            Deze Voorwaarden worden beheerst door en geïnterpreteerd in
            overeenstemming met de wetten van [Uw Jurisdictie]. Eventuele
            geschillen die voortvloeien uit deze Voorwaarden of uw gebruik van
            de Dienst zullen worden opgelost in de rechtbanken van [Uw
            Jurisdictie].
          </p>

          <h2>14. Scheidbaarheid</h2>
          <p>
            Als enige bepaling van deze Voorwaarden ongeldig of niet-afdwingbaar
            wordt bevonden, blijven de overige bepalingen volledig van kracht.
            De ongeldig of niet-afdwingbare bepaling wordt vervangen door een
            geldige bepaling die het beste de bedoeling van de oorspronkelijke
            bepaling weerspiegelt.
          </p>

          <h2>15. Gehele Overeenkomst</h2>
          <p>
            Deze Voorwaarden, samen met onze{" "}
            <a href="../privacy">Privacyverklaring</a>, vormen de volledige
            overeenkomst tussen u en All-Active-It met betrekking tot uw gebruik
            van de Dienst. Alle eerdere overeenkomsten, communicatie of
            afspraken, hetzij mondeling of schriftelijk, worden vervangen door
            deze Voorwaarden.
          </p>

          <h2>16. Contactinformatie</h2>
          <p>
            Als u vragen heeft over deze Voorwaarden, neem dan contact met ons
            op via:{" "}
            <a href="mailto:support@all-active-it.com">
              support@all-active-it.com
            </a>
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
