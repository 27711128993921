import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const PrivacyPolicy = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        <div style={{ margin: "3vw" }}>
          <h1>Privacyverklaring</h1>
          <p>
            <strong>Ingangsdatum:</strong> 01-10-2024
          </p>

          <p>
            Welkom bij All-Active-It. Deze privacyverklaring legt uit hoe we uw
            persoonlijke gegevens verzamelen, gebruiken, opslaan en delen
            wanneer u onze diensten gebruikt. Door gebruik te maken van onze
            diensten, stemt u in met de praktijken die in deze verklaring worden
            beschreven.
          </p>

          <h2>1. Gegevens die we verzamelen</h2>
          <p>
            We verzamelen verschillende soorten informatie om onze diensten te
            leveren, waaronder:
          </p>
          <ul>
            <li>
              Persoonlijke identificatiegegevens (zoals naam, e-mailadres, etc.)
            </li>
            <li>Gegevens over uw club en leden</li>
            <li>Informatie over uw gebruik van de diensten</li>
            <li>Betaalinformatie (indien van toepassing)</li>
          </ul>

          <h2>2. Hoe we uw gegevens gebruiken</h2>
          <p>We gebruiken uw gegevens voor de volgende doeleinden:</p>
          <ul>
            <li>Om onze diensten aan te bieden en te beheren.</li>
            <li>Om u klantenservice en ondersteuning te bieden.</li>
            <li>Om betalingen te verwerken (indien van toepassing).</li>
            <li>Om u op de hoogte te houden van updates en nieuwe functies.</li>
            <li>Voor het verbeteren van onze diensten en gebruikservaring.</li>
          </ul>

          <h2>3. Delen van uw gegevens</h2>
          <p>
            We delen uw gegevens niet met derden, behalve in de volgende
            situaties:
          </p>
          <ul>
            <li>Wanneer we wettelijke verplichtingen hebben om dit te doen.</li>
            <li>
              In het geval van een fusie, overname of verkoop van onze activa.
            </li>
          </ul>

          <h2>4. Gegevensbeveiliging</h2>
          <p>
            We nemen de beveiliging van uw persoonlijke gegevens serieus en
            implementeren redelijke technische en organisatorische maatregelen
            om deze te beschermen tegen ongeoorloofde toegang, verlies of
            vernietiging.
          </p>

          <h2>5. Uw rechten</h2>
          <p>
            U heeft recht op toegang tot uw persoonlijke gegevens en het recht
            om deze te corrigeren of te verwijderen. U kunt ook bezwaar maken
            tegen de verwerking van uw gegevens of deze beperken. Neem contact
            met ons op via de onderstaande contactgegevens als u deze rechten
            wilt uitoefenen.
          </p>

          <h2>6. Wijzigingen in deze Privacyverklaring</h2>
          <p>
            We behouden ons het recht voor om deze privacyverklaring op elk
            moment te wijzigen. We zullen u op de hoogte stellen van belangrijke
            wijzigingen door de bijgewerkte verklaring op onze website te
            plaatsen. We raden aan deze verklaring regelmatig te controleren.
          </p>

          <h2>7. Contactinformatie</h2>
          <p>
            Als u vragen of opmerkingen heeft over deze privacyverklaring, neem
            dan contact met ons op via:{" "}
            <a href="mailto:support@all-active-it.com">
              support@all-active-it.com
            </a>
          </p>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
