import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();

const GROEP_API_BASE_URL = process.env.REACT_APP_API_URL +'groep'
class GroepService {
  async GetAll () {
    return await axios.get(GROEP_API_BASE_URL + '/all')
  }



async Update (groep) {
  return await axios({
    method: 'patch',
    url: GROEP_API_BASE_URL + '/update',
    headers: {},
    data: {
      id: groep.id,
      nieuwenaam: groep.naam
    }

  }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      return (error.response)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }
    console.log(error.config)
  })
}


async New (groep) {
  return await axios({
    method: 'post',
    url: GROEP_API_BASE_URL + '/new/' + groep,
    headers: {},

  }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      return (error.response)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }
    console.log(error.config)
  })
}
}

export default new GroepService()
