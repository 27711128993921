import React, { useEffect, useState, useRef } from "react";
import SessionHandler from "../../Sessionhandler/SessionHandler";

export default function Evenementen() {
  const Retry = () => {
    window.location.reload();
  };

  return (
    <div style={styles.container}>
      <svg
        width="10vw"
        height="10vw"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#000000"
      >
        <path
          d="M9 22L12.0005 19M15 16L12.0005 19M12.0005 19L9 16M12.0005 19L15 22"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M20 17.6073C21.4937 17.0221 23 15.6889 23 13C23 9 19.6667 8 18 8C18 6 18 2 12 2C6 2 6 6 6 8C4.33333 8 1 9 1 13C1 15.6889 2.50628 17.0221 4 17.6073"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      <h1 style={styles.heading}>No Connection to Servers Found</h1>
      <p style={styles.message}>
        We are unable to connect to our servers at the moment. Please check your
        internet connection or try again later.
      </p>
      <div style={styles.iconContainer}></div>
      <button style={styles.button} onClick={() => Retry()}>
        Retry
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  heading: {
    fontSize: "2rem",
    margin: "20px 0",
    color: "#343a40",
  },
  message: {
    fontSize: "1.2rem",
    margin: "10px 0",
    color: "#6c757d",
  },
  iconContainer: {
    margin: "20px 0",
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#6c757d",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
  },
};
