import React from "react";
import Header from "./Header";
import Image from "../../Images/Logo-MTB-Oosterhout_eind.png";
import Footer from "./Footer";

const Homepage = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        <section className="hero">
          <h2>Welkom bij All-Active-IT</h2>
          <p>
            Wij managen het ledenbestand zodat u kunt focussen op het sporten!
          </p>
        </section>
        {/* Type 1 Block */}
        <div className="block-container ">
          <div className="block" style={{ backgroundColor: "#071c6d" }}>
            <h3>Wat biedt All-Active-It aan?</h3>
            <p>
              Onze app is ontwikkeld met het doel om sportclubs te ondersteunen
              bij het beheren van aan- en afmeldingen van hun leden. Door het
              gebruik van deze app wordt het administratieve proces rond
              lidmaatschappen aanzienlijk vereenvoudigd. Sportclubs kunnen op
              een gebruiksvriendelijke manier bijhouden wie zich aanmeldt voor
              trainingen, wedstrijden of andere evenementen, en wie zich
              afmeldt. De app biedt clubs de mogelijkheid om op elk moment een
              actueel overzicht te hebben van hun ledenaantallen en deelname.
              Dit zorgt niet alleen voor een betere organisatie, maar ook voor
              een verhoogde betrokkenheid van de leden, doordat informatie snel
              en overzichtelijk beschikbaar is. Daarnaast draagt de app bij aan
              een gestroomlijnde communicatie tussen de club en haar leden, wat
              resulteert in een efficiënter beheer van de clubactiviteiten.
            </p>
          </div>
          <div
            className="block"
            style={{ backgroundColor: "#e9b50c", visibility: "hidden" }}
          >
            <img src="image1.png" alt="Image on the Right" />
          </div>
        </div>

        {/* <div className="block-container type-2">
          <div
            className="big-block"
            style={{ backgroundColor: "#f5cf5c", color: "#0c0c0c" }}
          >
            <h3>Text on the Left</h3>
            <p>
              This is some text aligned to the left. It can be a description, an
              explanation, or anything else relevant to your content.
            </p>
          </div>
          <div className="small-block" style={{ backgroundColor: "#0d21a1" }}>
            <img src="image2.png" alt="Image on the Right" />
          </div>
        </div> */}

        <div className="block-container type-2">
          <div className="small-block" style={{ visibility: "hidden" }}></div>
          <div
            className="big-block"
            style={{ backgroundColor: "#f5cf5c", color: "#0c0c0c" }}
          >
            <h3>Ons Doel</h3>
            <p>
              Onze passie ligt bij sporten. Het doel van ons systeem is daarom
              ook om zoveel boekhouding, aan en afmelding van de clubs uit
              handen te nemen en dit automatisch te doen. Hiernaast sponsoren we
              ook enkele clubs zodat zij mensen net zo fanatiek over sport
              kunnen maken als we zelf zijn!
              <br />
              <br />
              Naast de sponsoring van deze clubs zijn ze ook nauw betrokken in
              het ontwikkelings proccess. Dit zorgt voor handige nieuwe features
              waar elke sportclub gebruik van kan maken!
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Homepage;
