import React, { useEffect, useState, useRef } from "react";
import LoginService from "../../Services/LoginService";
import { Messages } from "primereact/messages";
import { useNavigate } from "react-router-dom";

function Login() {
  const [PW1, setPW1] = useState(null);
  const [PW2, setPW2] = useState(null);
  const [Token, setToken] = useState(null);
  const msgs = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    setToken(token);
  }, []);

  const Loginhandler = (event) => {
    event.preventDefault();

    LoginService.UpdatePassword(Token, PW1, PW2).then((result) => {
      console.log(result);
      if (result.data.type === "SUCCES") {
        msgs.current.show({
          severity: "success",
          detail: "Wachtwoord update gelukt!",
          sticky: true,
        });
        setTimeout(() => {
          navigate("../");
        }, 2000);
      } else {
        for (
          let index = 0;
          index < result.data.payload.details.length;
          ++index
        ) {
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details[index],
            sticky: true,
          });
        }
      }
    });
  };

  return (
    <>
      <Messages name="message" ref={msgs} />
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={(e) => Loginhandler(e)}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Wachtwoord veranderen</h3>
            <div className="text-center"></div>
            <div className="form-group mt-3">
              <label>Wachtwoord</label>
              <input
                type="password"
                name="password1"
                onChange={(e) => setPW1(e.target.value)}
                className="form-control mt-1"
                placeholder="enter wachtwoord"
              />
            </div>
            <div className="form-group mt-3">
              <label>Herhaal wachtwoord</label>
              <input
                type="password"
                name="password2"
                onChange={(e) => setPW2(e.target.value)}
                className="form-control mt-1"
                placeholder="herhaal wachtwoord"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Aanvragen
              </button>
            </div>
            <p className="text-center mt-2">
              <a href="./">Login</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
