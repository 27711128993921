import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import EvenementenService from "../../../Services/EvenementenService";
import { Tag } from "primereact/tag";

export default function Evenementen() {
  const [Evenementen, setEvenementen] = useState(null);
  const [ExpendedRows, setExpandedRows] = useState(null);
  const msgs = useRef(null);

  const allowExpansion = (rowData) => {
    try {
      return rowData.deelnemers.length > 0;
    } catch {
      return false;
    }
  };

  const parseDate = (dateString) => {
    const parts = dateString.split(/[/ :]/);
    if (parts.length === 6) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const hours = parseInt(parts[3], 10);
      const minutes = parseInt(parts[4], 10);
      const seconds = parseInt(parts[5], 10);
      return new Date(year, month, day, hours, minutes, seconds);
    }
    throw new Error("Invalid date format");
  };

  const onRowEditComplete = (e) => {
    let _Evenementen = [...Evenementen];
    let { newData, index } = e;
    _Evenementen[index] = newData;

    if (newData.startdatumtijd && newData.einddatumtijd) {
      try {
        const formattedStartDate = parseDate(newData.startdatumtijd);
        const formattedEndDate = parseDate(newData.einddatumtijd);

        newData.startdatumtijd = formattedStartDate.toISOString();
        newData.einddatumtijd = formattedEndDate.toISOString();
      } catch (error) {
        console.error("Invalid date format. Please check the date values.");
        return;
      }
    } else {
      console.error("startdatumtijd or einddatumtijd is missing.");
      return;
    }

    newData.deelnemers.forEach((deelnemer) => {
      deelnemer.groep = deelnemer.groep.id;
    });

    EvenementenService.Update(newData)
      .then((result) => {
        if (result.data.type === "SUCCES") {
          setEvenementen(_Evenementen);
          msgs.current.show({
            severity: "success",
            detail: "Update gelukt!",
            sticky: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details,
            sticky: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating event: ", error);
        msgs.current.show({
          severity: "error",
          detail: "Update failed. Please try again.",
          sticky: true,
        });
      });
  };

  const GetText = (data) => {
    switch (data.aangemeld) {
      case 0:
        return "Niks Aangegeven";
      case 1:
        return "Aangemeld";
      case 2:
        return "Afgemeld";
      default:
        return "ERROR!!";
    }
  };

  const GetText2 = (data) => {
    switch (data.opkomendagen) {
      case 0:
        return "Niet ingevoerd";
      case 1:
        return "Wel aanwezig";
      case 2:
        return "Niet aanwezig";
      default:
        return "ERROR!!";
    }
  };

  const GetSeverity = (data) => {
    switch (data.aangemeld) {
      case 0:
        return "warning";
      case 1:
        return "success";
      case 2:
        return "danger";
      default:
        return "danger";
    }
  };

  const GetSeverity2 = (data) => {
    switch (data.opkomendagen) {
      case 0:
        return "warning";
      case 1:
        return "success";
      case 2:
        return "danger";
      default:
        return "danger";
    }
  };

  const AanmeldBodyTemplate = (rowData) => {
    return <Tag value={GetText(rowData)} severity={GetSeverity(rowData)}></Tag>;
  };

  const OpdagenBodyTemplate = (rowData) => {
    return (
      <Tag value={GetText2(rowData)} severity={GetSeverity2(rowData)}></Tag>
    );
  };

  const rowExpansionTemplate = (Data) => {
    return (
      <div className="p-3">
        <h5>Aan en afmeldingen voor {Data.titel}</h5>
        <h8>{Data.text}</h8>
        <DataTable value={Data.deelnemers}>
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="typeDeelnemer"
            header="Type deelnemer"
            sortable
          ></Column>
          <Column field="lid.voornaam" header="Voornaam" sortable></Column>
          <Column field="lid.achternaam" header="Achternaam" sortable></Column>
          <Column
            field="groep.naam"
            header="Groep"
            sortable
            filter
            filterPlaceholder="Search"
          ></Column>
          <Column
            field="opkomendagen"
            header="Opkomen dagen"
            body={OpdagenBodyTemplate}
            sortable
          ></Column>
          <Column
            field="aangemeld"
            header="Aangemeld"
            body={AanmeldBodyTemplate}
            sortable
          ></Column>
          <Column headerStyle={{ width: "4rem" }}></Column>
        </DataTable>
      </div>
    );
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  useEffect(() => {
    EvenementenService.GetAll().then((result) => {
      const formattedEvenementen = result.data.payload.map((event) => {
        const originalStartDate = new Date(event.startdatumtijd);
        const originalEndDate = new Date(event.einddatumtijd);

        const formattedStartDate = `${originalStartDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(originalStartDate.getMonth() + 1)
          .toString()
          .padStart(
            2,
            "0"
          )}/${originalStartDate.getFullYear()} ${originalStartDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${originalStartDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${originalStartDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
        const formattedEndDate = `${originalEndDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(originalEndDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${originalEndDate.getFullYear()} ${originalEndDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${originalEndDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${originalEndDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;

        return {
          ...event,
          startdatumtijd: formattedStartDate,
          einddatumtijd: formattedEndDate,
        };
      });

      setEvenementen(formattedEvenementen);
    });
  }, []);

  return (
    <>
      <h1 style={{ marginLeft: "3vw" }}>Evenementen</h1>
      <a href="/NieuwEvenement" style={{ marginLeft: "4vw" }}>
        Nieuw evenement
      </a>
      <Messages ref={msgs} />
      <div className="card">
        <DataTable
          sortField="startdatumtijd"
          onRowEditComplete={onRowEditComplete}
          editMode="row"
          dataKey="id"
          value={Evenementen}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          tableStyle={{ minWidth: "50rem" }}
          expandedRows={ExpendedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="titel"
            header="Titel"
            sortable
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="startdatumtijd"
            header="Startdatum & tijd"
            sortable
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="einddatumtijd"
            header="Einddatum & tijd"
            sortable
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
}
