import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Prijs = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        {/* Type 1 Block */}
        <div className="block-container ">
          <div className="block" style={{ backgroundColor: "#071c6d" }}>
            <h3>Prijzen:</h3>
            <p>
              Omdat de applicatie nog in ontwikkeling is zijn er helaas nog geen
              vaste prijzen. Toch is er in overleg iets af te sluiten.
            </p>
          </div>
          <div
            className="block"
            style={{ backgroundColor: "#e9b50c", visibility: "hidden" }}
          >
            <img src="image1.png" alt="Image on the Right" />
          </div>
        </div>
      </main>
      <section className="hero">
        <h2>Geïnteresseerd?</h2>
        <p>
          Voor vragen neem gerust contact met ons op!
          <br />
          klik{" "}
          <a style={{ color: "#e9b50c" }} href="../contact">
            hier
          </a>{" "}
          om contact met ons op te nemen!
        </p>
      </section>
      <br /> <br /> <Footer />
    </div>
  );
};

export default Prijs;
