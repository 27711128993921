import React, { useState, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import GroepService from "../../../Services/GroepService";
import { useNavigate } from "react-router-dom";

export default function NieuwGroep() {
  const navigate = useNavigate();
  const [Groep, setGroep] = useState({
    naam: "",
  });

  const msgs = useRef(null);
  const Upload = (e) => {
    e.preventDefault();
    GroepService.New(Groep.naam).then((result) => {
      console.log(result);
      try {
        if (result.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "groep toevoegen gelukt!",
            sticky: true,
          });
          setTimeout(() => {
            navigate("../Groepen");
          }, 2000);
        } else {
          for (
            let index = 0;
            index < result.data.payload.details.length;
            ++index
          ) {
            msgs.current.show({
              severity: "error",
              detail: result.data.payload.details[index],
              sticky: true,
            });
          }
        }
      } catch {}
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroep((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={(e) => Upload(e)} target="hidden-iframe">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Nieuw Groep</h3>
          <div className="text-center"></div>
          <Messages ref={msgs} />
          <div className="form-group mt-3">
            <label>Naam</label>
            <InputText
              name="naam"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="Enter Naam"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              voeg toe
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
