import axios from "axios";
import SessionHandler from "../Sessionhandler/SessionHandler";





const LID_API_BASE_URL = process.env.REACT_APP_API_URL + "lid";
class LoginService {
    Login(email, wachtwoord){
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["ClubID"];
        return axios.post(LID_API_BASE_URL + "/login", {
            email : email,
            wachtwoord : wachtwoord
        }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return(error.response)
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                SessionHandler.testConnection();
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                SessionHandler.testConnection();
              }
              console.log(error.config);    
        })
        
        
        
    }

    async forgotpassword(email)
  {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["ClubID"];
    return axios.post(LID_API_BASE_URL + "/wachtwoordvergeten", {
        email : email,
    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return(error.response)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);    
          
    })
  }

  async UpdatePassword(Token, PW1, PW2)
  {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["ClubID"];
    return axios.patch(LID_API_BASE_URL + "/Update/Wachtwoord", {
        token : Token,
        nieuwwachtwoord : PW1,
        herhalingwachtwoord: PW2
    }).catch(function (error) {
      console.log(error.request);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return(error.response)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);    
    })
  }

async ActivateAcount(Token, PW1, PW2)
  {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["ClubID"];
    return axios.patch(LID_API_BASE_URL + "/Update/Wachtwoord", {
        token : Token,
        nieuwwachtwoord : PW1,
        herhalingwachtwoord: PW2
    }).catch(function (error) {
      console.log(error.request);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return(error.response)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);    
    })
  }


  async AcceptDelete(PW, Token)
  {
    console.log(Token);
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["ClubID"];
    return await axios({
      method: 'delete',
      url: LID_API_BASE_URL + "/Delete/Accept",
      data: {
        token: Token,
        wachtwoordcontrole: PW,
      }
    }).catch(function (error) {
      console.log(error.request);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return(error.response)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);    
    })
  }



}


export default new LoginService()