import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Contact = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        <section className="hero">
          <h2>Geïnteresseerd?</h2>
          <p>
            Voor vragen neem gerust contact met ons op!
            <br />
            Zie de onderstaande gegevens voor een manier om contact met ons op
            te nemen!
          </p>
        </section>
        {/* Type 1 Block */}
        <div className="block-container ">
          <div className="block" style={{ backgroundColor: "#071c6d" }}>
            <h3>Contact:</h3>
            <p>
              Email: Contact@All-Active-IT.com <br />
              LinkedIn : https://www.linkedin.com/showcase/all-active-it/
            </p>
          </div>
          <div
            className="block"
            style={{ backgroundColor: "#e9b50c", visibility: "hidden" }}
          >
            <img src="image1.png" alt="Image on the Right" />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
