import React, { useState, useRef } from "react";
import LoginService from "../../Services/LoginService";
import { Messages } from "primereact/messages";
import SessionHandler from "../../Sessionhandler/SessionHandler";

function Login() {
  const [Email, setEmail] = useState("NieuwEmail2@email.nl");
  const [Wachtwoord, SetWachtwoord] = useState("!Veiligwachtwoord123"); // tijdelijk standaard wachtwoord en email al ingevoerd zodat ik die niet de hele tijd hoef in te voeren.
  const msgs = useRef(null);
  try {
    SessionHandler.clear();
  } catch {}

  const Loginhandler = (event) => {
    event.preventDefault();
    LoginService.Login(Email, Wachtwoord).then((result) => {
      try {
        if (result.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "inloggen succesvol, automatisch aan het doorsturen!",
            sticky: true,
          });
          SessionHandler.set(result.data.payload);
          window.location.reload(false);
        } else {
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details,
            sticky: true,
          });
        }
      } catch {}
    });
  };

  return (
    <>
      <Messages name="message" ref={msgs} />
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={(e) => Loginhandler(e)}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">inloggen</h3>
            <div className="text-center"></div>
            <div className="form-group mt-3">
              <label>Email adres</label>
              <input
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control mt-1"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group mt-3">
              <label>Wachtwoord</label>
              <input
                name="wachtwoord"
                onChange={(e) => SetWachtwoord(e.target.value)}
                type="password"
                className="form-control mt-1"
                placeholder="Enter wachtwoord"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
            <p className="text-center mt-2">
              <a href="/WachtwoordVergeten">Wachtwoord vergeten??</a>
            </p>
            <p className="text-center mt-2">
              <a href="../../">home</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
