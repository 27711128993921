import React, { useState, useRef } from "react";
import LoginService from "../../Services/LoginService";
import { Messages } from "primereact/messages";
import { useNavigate } from "react-router-dom";
import SessionHandler from "../../Sessionhandler/SessionHandler";

function WachtwoordVergeten() {
  const [Email, setEmail] = useState(null);
  const msgs = useRef(null);
  SessionHandler.clear();
  const navigate = useNavigate();
  const passwordhandler = (event) => {
    event.preventDefault();
    console.log(Email);

    LoginService.forgotpassword(Email).then((result) => {
      console.log(result);
      try {
        if (result.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "Als het email adress bestaat is er nu een mail verstuurd",
            sticky: true,
          });
          setTimeout(() => {
            navigate("../");
          }, 2000);
        } else {
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details,
            sticky: true,
          });
        }
      } catch {}
    });
  };

  return (
    <>
      <Messages name="message" ref={msgs} />
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={(e) => passwordhandler(e)}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Wachtwoord vergeten</h3>
            <div className="text-center"></div>
            <div className="form-group mt-3">
              <label>Email adres</label>
              <input
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control mt-1"
                placeholder="Enter email"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Aanvragen
              </button>
            </div>
            <p className="text-center mt-2">
              <a href="./">Login</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
}

export default WachtwoordVergeten;
