import React from "react";

const Homepage = () => {
  return (
    <div>
      <header>
        <h1>Welkom in het aan en afmeld systeem van MTB Oosterhout</h1>
      </header>
    </div>
  );
};

export default Homepage;
