import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import GroepService from "../../../Services/GroepService";

export default function Groepen() {
  const [Groepen, SetGroepen] = useState(null);
  const msgs = useRef(null);

  const onRowEditComplete = (e) => {
    console.log(e);
    let _Groepen = [...Groepen];
    let { newData, index } = e;
    _Groepen[index] = newData;
    GroepService.Update(newData).then((result) => {
      console.log(result);
      try {
        if (result.data.type === "SUCCES") {
          SetGroepen(_Groepen);
          msgs.current.show({
            severity: "success",
            detail: " Update gelukt!",
            sticky: false,
          });
        } else {
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details,
            sticky: true,
          });
        }
      } catch {}
    });
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  useEffect(() => {
    GroepService.GetAll().then((result) => SetGroepen(result.data.payload));
  }, []);

  return (
    <>
      <h1 style={{ marginLeft: "3vw" }}>Groepen</h1>
      <a href="/NieuweGroep" style={{ marginLeft: "4vw" }}>
        {" "}
        Nieuwe Groep
      </a>
      <Messages ref={msgs} />
      <div className="card">
        <DataTable
          onRowEditComplete={onRowEditComplete}
          editMode="row"
          dataKey="naam"
          value={Groepen}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="naam"
            header="naam"
            sortable
            filter
            filterPlaceholder="Search"
            editor={(options) => textEditor(options)}
            style={{ width: "25%" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
}
