import React, { useState, useRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import GroepService from "../../../Services/GroepService";
import LidService from "../../../Services/LidService";

export default function NieuwLid() {
  const [Groepen, SetGroepen] = useState(null);
  const [SelectedGroep, SetGroep] = useState("");
  const [Lid, setLid] = useState({
    voornaam: "",
    achternaam: "",
    email: "",
    groep: "",
  });

  useEffect(() => {
    GroepService.GetAll().then((result) => SetGroepen(result.data.payload));
  }, []);

  const msgs = useRef(null);
  const Upload = (e) => {
    msgs.current.show({
      severity: "success",
      detail: "Lid aan het toevoegen, dit kan even duren",
      sticky: false,
    });
    e.preventDefault();
    console.log(SelectedGroep);
    Lid.groep = SelectedGroep.id;
    LidService.nieuw(Lid).then((result) => {
      console.log(result);
      try {
        if (result.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "Lid toevoegen gelukt!",
            sticky: true,
          });
        } else {
          for (
            let index = 0;
            index < result.data.payload.details.length;
            ++index
          ) {
            msgs.current.show({
              severity: "error",
              detail: result.data.payload.details[index],
              sticky: true,
            });
          }
        }
      } catch {}
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLid((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={(e) => Upload(e)} target="hidden-iframe">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Nieuw Lid</h3>
          <div className="text-center"></div>
          <Messages ref={msgs} />
          <div className="form-group mt-3">
            <label>Voornaam</label>
            <InputText
              name="voornaam"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="Enter voornaam"
            />
          </div>

          <div className="form-group mt-3">
            <label>Achternaam</label>
            <InputText
              name="achternaam"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="Enter achternaam"
            />
          </div>

          <div className="form-group mt-3">
            <label>Email </label>
            <InputText
              name="email"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="voorbeeld@Email.nl"
            />
          </div>
          <div className="form-group mt-3">
            <label>Groep</label>
            <Dropdown
              name="groep"
              onChange={(e) => SetGroep(e.value)}
              value={SelectedGroep}
              optionLabel="naam"
              options={Groepen}
              className="form-control mt-1"
              placeholder="selecteer groep"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              voeg toe
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
