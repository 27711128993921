import axios from 'axios'
import jwt from 'jwt-decode'
const CONNECTION_API_BASE_URL = process.env.REACT_APP_API_URL
class SessionHandler {


    async  testConnection() {
        try {
           const response = await axios.get(CONNECTION_API_BASE_URL + "HealthCheck", {
    headers: {
      'Authorization': '' // This will override the default Authorization header
    }
  });
          // If the request is successful, log the response and return true
          console.log(response.data);
          console.log(response.status);
          console.log(response.headers);
          return true;
        } catch (error) {
          // Handle errors
          if (error.response) {
            // The server responded with a status code outside of the range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something else happened in setting up the request
            console.log('Error', error.message);
          }
          console.log(error.config);
          return false;
        }
      }
      

      set(JWT) {
        sessionStorage.setItem("JWT", JWT);
    
        let decodedToken = jwt(JWT);
    
        // Check if decodedToken is a string and parse it if necessary
        if (typeof decodedToken === 'string') {
            decodedToken = JSON.parse(decodedToken);
        }
    
        // Parse clubauth if it's still a string
        if (typeof decodedToken.clubauth === 'string') {
            decodedToken.clubauth = JSON.parse(decodedToken.clubauth);
        }
    
        // Assuming the first entry in clubauth is the desired club
        if (decodedToken.clubauth && decodedToken.clubauth.length > 0) {
            console.log("Club:", decodedToken.clubauth[0].club);
            sessionStorage.setItem("ClubID", decodedToken.clubauth[0].club);  // Store the club value
        } else {
            console.error("Club not found in the decoded token.");
        }
    }
    
    
    
    
    
      

    checklogin(){
        if(sessionStorage.getItem("JWT") != null)
            {
                let decodedToken;
                try {
                    decodedToken = jwt(sessionStorage.getItem("JWT"));
                } catch (error) {
                    return false
                }

                const currentTimestamp = Math.floor(Date.now() / 1000);
                if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
                    sessionStorage.clear();
                return false;
                }
                else{
                    return true
                }
            }
    else{
    return false
    }

    }




    getToken(){
        return sessionStorage.getItem("JWT");
    }

    clear(){
         return sessionStorage.clear();
        }

   

    getID(){
        try {
            let decodedToken = jwt(sessionStorage.getItem("JWT"));
            console.log(decodedToken)
            return(decodedToken.userId)
        } catch (error) {
            return null
        }
        }

    GetClub(){
      console.log(sessionStorage.getItem("ClubID"));
      let currentClub = parseInt(sessionStorage.getItem("ClubID"), 10); // JWT is a normally string, this converts the id of club to an integer
      return currentClub
    }

    setClub(ClubID){
      sessionStorage.setItem("ClubID", ClubID)
    }

    GetClubAuth() {
      try {
        let decodedToken = jwt(sessionStorage.getItem("JWT"));
        console.log(decodedToken.clubauth);
        return(decodedToken.clubauth)
    } catch (error) {
      console.log(error);
        return null
    }
  }
  
}




export default new SessionHandler;