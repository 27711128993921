import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SessionHandler from './SessionHandler';
import NoConnectionBE from '../components/Other/NoConnectionToBackEnd';

function ProtectedRoute({ element: Element }) {
  const [isConnected, setIsConnected] = useState(null);

  useEffect(() => {
    const checkConnection = async () => {
      const connected = await SessionHandler.testConnection();
      setIsConnected(connected);
    };
    checkConnection();
  }, []);

  if (isConnected === null) {
    return <div>Loading...</div>;
  }

  if (!isConnected) {
    return <NoConnectionBE />;
  }

  return <Element  />; // Renders the matched child route element
}

export default ProtectedRoute;
