import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";

const FAQItem = ({ question, answer, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  // Open the FAQ if the URL hash matches the FAQ id on load
  useEffect(() => {
    if (window.location.hash === `#${id}`) {
      setIsOpen(true);
    }
  }, [id]);

  return (
    <div
      id={id}
      style={{ marginBottom: "1.5vw", borderRadius: "8px", overflow: "hidden" }}
    >
      <h3
        onClick={toggleAnswer}
        style={{
          cursor: "pointer",
          backgroundColor: "#0D21A1",
          color: "#FFFFFF",
          padding: "1vw",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {question}
        <span>{isOpen ? "-" : "+"}</span>
      </h3>
      {isOpen && (
        <p
          style={{
            padding: "1vw",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
          }}
        >
          {answer}
        </p>
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="homepagebeforelogin">
      <Header />
      <main>
        <div style={{ margin: "3vw" }}>
          <h1 style={{ color: "#011638" }}>Veelgestelde Vragen</h1>
          <h2 style={{ color: "#071c6d" }}>Privacy</h2>
          <FAQItem
            id="start-date"
            question="Wanneer is deze privacyverklaring van kracht?"
            answer="De ingangsdatum van deze privacyverklaring is 01-10-2024."
          />
          <FAQItem
            id="where-find-privacy"
            question="waar kan ik de privacyverklaring vinden?"
            answer={
              <div>
                <p>De privacyverklaring is te vinden door</p>{" "}
                <a href="https://All-Active-It.com/Privacy"> HIER </a>
                <p> te klikken</p>
              </div>
            }
          />
          <FAQItem
            id="where-find-tos"
            question="waar kan ik de terms of service vinden?"
            answer={
              <div>
                <p>De privacyverklaring is te vinden door</p>{" "}
                <a href="https://All-Active-It.com/TermsofService"> HIER </a>
                <p> te klikken</p>
              </div>
            }
          />
          <FAQItem
            id="data-collection"
            question="Waarom verzamelt All-Active-It mijn persoonlijke gegevens?"
            answer="We verzamelen uw persoonlijke gegevens om onze diensten te kunnen aanbieden, beheren en verbeteren. Daarnaast gebruiken we gegevens voor klantenservice, betalingsverwerking (indien van toepassing) en om u op de hoogte te houden van updates en nieuwe functies."
          />
          <FAQItem
            id="data-types"
            question="Welke soorten gegevens verzamelt All-Active-It?"
            answer={
              <ul>
                <li>
                  Persoonlijke identificatiegegevens (zoals naam, e-mailadres,
                  etc.)
                </li>
                <li>Gegevens over uw club en leden</li>
                <li>Informatie over uw gebruik van de diensten</li>
                <li>Betaalinformatie (indien van toepassing)</li>
              </ul>
            }
          />
          <FAQItem
            id="data-sharing"
            question="Met wie deelt All-Active-It mijn gegevens?"
            answer="Uw gegevens worden niet gedeeld met derden, tenzij dit wettelijk vereist is of bij een fusie, overname of verkoop van onze activa."
          />
          <FAQItem
            id="data-protection"
            question="Hoe beschermt All-Active-It mijn gegevens?"
            answer="We nemen de beveiliging van uw gegevens serieus en implementeren technische en organisatorische maatregelen om uw gegevens te beschermen tegen ongeoorloofde toegang, verlies of vernietiging."
          />
          <FAQItem
            id="user-rights"
            question="Welke rechten heb ik over mijn persoonlijke gegevens?"
            answer="U heeft het recht op toegang tot uw gegevens, en om deze te corrigeren of te verwijderen. U kunt ook bezwaar maken tegen de verwerking van uw gegevens of de verwerking beperken. Neem contact met ons op via support@all-active-it.com om uw rechten uit te oefenen."
          />
          <FAQItem
            id="change-user-info"
            question="Hoe bewerk ik mijn gegevens?"
            answer={
              <div>
                <b>
                  om delen van uw account te verwijderen volgt u de volgende
                  stappen:
                </b>
                <p>
                  Als u ingelogd bent op de website met u account kunt u
                  navigeren naar accountsettings en klikt u op account
                  instellingen. Hier kunt u uw persoonlijke Informatie
                  veranderen en verwijderen.
                </p>
              </div>
            }
          />
          <FAQItem
            id="data-deletion"
            question="Hoe verwijder ik mijn gegevens?"
            answer={
              <div>
                <p>
                  Als u ingelogd bent met u account op de website kunt u
                  navigeren naar accountsettings en klikt u op account
                  verwijderen. Hierna wordt een mail gestuurd naar uw email
                  adres met de link om uw account te verwijderen. Dit verwijderd
                  alle gegevens op ons systeem die aan u gebonden zijn.
                </p>
                <b>
                  Dit verwijderd niet de clubs en evenementen waar u aan
                  verbonden bent, alleen de koppelingen van u naar de clubs en
                  evenementen.
                </b>
                <p>
                  Andere leden van de club krijgen geen melding dat uw account
                  is verwijderd, ook bent u niet meer te vinden via de club.
                </p>
              </div>
            }
          />
          <FAQItem
            id="policy-changes"
            question="Kan deze privacyverklaring veranderen?"
            answer="Ja, we behouden ons het recht voor om deze privacyverklaring te wijzigen. We raden aan om regelmatig de bijgewerkte versie op onze website te controleren."
          />
          <FAQItem
            id="contact-info"
            question="Hoe kan ik contact opnemen met All-Active-It?"
            answer={
              <p>
                Voor vragen of opmerkingen kunt u contact opnemen via:{" "}
                <a href="mailto:support@all-active-it.com">
                  support@all-active-it.com
                </a>
              </p>
            }
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default FAQ;
