import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();

const CLUB_API_BASE_URL = process.env.REACT_APP_API_URL +'club'
class ClubService {
  async GetFromLid (lidid) {
    return await axios.get(CLUB_API_BASE_URL + '/fromlid/' + lidid)
  }


}

export default new ClubService()
